import React, { Suspense, lazy, useMemo } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { isEmpty } from 'lodash';
import PrivateRoute from '../Components/PrivateRoute/PrivateRoute';
import Spinner from '../Components/UIElements/Spinner';
import {
  PostGraduateForm,
  CoursePeriodsForm,
  CertificateForm,
  CoordinationForm,
  TestAppointmentsForm,
  FeesForm,
  ArabicDepProgramsForm,
  ArabicSkillsForm,
  ArabicLevelsForm,
  TrainingForm,
  CoordinationFaculties,
  PostGraduateFaculties,
  EquationForm,
  EffectiveInformationFrom,
  CandidacyLimitForm,
  PaymentEffectiveInformationFrom,
} from '../Containers/DynamicSettings/DynamicSettings';
import GeneralLookups from '../Containers/GeneralLookups';
import PaymentEffectiveInformationList from '../Containers/paymentEffectiveInformationList/paymentEffectiveInformationList';

const StudyStagesUg = lazy(() => import('../Containers/StudyStagesUg/StudyStagesUg'));
const ListLookups = lazy(() => import('../Containers/ListLookups/ListLookups'));
const ListLookupsPost = lazy(() => import('../Containers/ListLookups/ListLookupsPost'));
const CertificateList = lazy(() => import('../Containers/CertificateList/CertificateList'));
const CoordinationList = lazy(() => import('../Containers/CoordinationList/CoordinationList'));
const EquationsList = lazy(() => import('../Containers/EquationsList/EquationsList'));
const PeriodsList = lazy(() => import('../Containers/PeriodsList/PeriodsList'));
const FeesList = lazy(() => import('../Containers/FeesList/FeesList'));
const EffectiveInformationList = lazy(() => import('../Containers/EffectiveInformationList/EffectiveInformationList'));
const CandidacyLimitList = lazy(() => import('../Containers/candidacyLimitList/CandidacyLimitList.js'));
const SignaturesList = lazy(() => import('../Containers/SignaturesList/SignaturesList'));
const PostManagementList = lazy(() => import('../Containers/PostManagementList/PostManagementList'));
const ArabicDepPrograms = lazy(() => import('../Containers/ArabicDepPrograms'));
const ArabicSkillsList = lazy(() => import('../Containers/ArabicDepSkills'));
const ArabicLevelsList = lazy(() => import('../Containers/ArabicDepLevels'));
const TestAppointmentsList = lazy(() => import('../Containers/TestAppointmentsList/TestAppointmentsList'));
const ArabicDepLookups = lazy(() => import('../Containers/ArabicDepLookups'));
const TrainingLookups = lazy(() => import('../Containers/TrainingLookups'));
const TrainingList = lazy(() => import('../Containers/Training'));
const NoMatchedRoutes = lazy(() => import('../Components/NoMatchedRoutes/NoMatchedRoutes'));

const Routes = (props) => {
  const { location } = props;
  const roleIds = useSelector(state => _get(state, 'userReducer.userData.roles', []).map(role => role.id));
  const isAuth = localStorage.getItem('accessToken');

  const ROLES_ROUTES = useMemo(() => ({
    PG_EMP: [
      <PrivateRoute path="/postGraduate" render={props => <ListLookupsPost {...props} />} />,
      <PrivateRoute path="/post-graduate-list" render={props => <PostManagementList {...props} />} />,

      <PrivateRoute path="/post-graduate-settings" render={props => <PostGraduateForm {...props} />} />,
      <PrivateRoute path="/post-graduate-faculties" render={props => <PostGraduateFaculties {...props} />} />,
    ],
    UG_EMP: [
      <PrivateRoute path="/certificates" render={props => <CertificateList {...props} />} />,
      <PrivateRoute path="/coordination-list" render={props => <CoordinationList {...props} />} />,
      <PrivateRoute exact path="/lookups" render={props => <ListLookups {...props} />} />,

      <PrivateRoute path="/certificate-form" component={CertificateForm} render={props => <CertificateForm {...props} />} />,
      <PrivateRoute path="/coordination-settings" render={props => <CoordinationForm {...props} />} />,
      <PrivateRoute path="/coordination-faculties" render={props => <CoordinationFaculties {...props} />} />,
    ],
    ARABIC_EMP: [
      <PrivateRoute exact path="/arabic-dep" render={props => <ArabicDepLookups {...props} />} />,
      <PrivateRoute exact path="/arabic-programs" render={props => <ArabicDepPrograms {...props} />} />,
      <PrivateRoute path="/arabic-skills-list" render={props => <ArabicSkillsList {...props} />} />,
      <PrivateRoute path="/arabic-levels-list" render={props => <ArabicLevelsList {...props} />} />,
      <PrivateRoute path="/test-appointments-list" render={props => <TestAppointmentsList {...props} />} />,
      <PrivateRoute path="/course-periods-list" render={props => <PeriodsList {...props} />} />,

      <PrivateRoute path="/arabic-programs-settings" render={props => <ArabicDepProgramsForm {...props} />} />,
      <PrivateRoute path="/arabic-skills-settings" component={ArabicSkillsForm} render={props => <ArabicSkillsForm {...props} />} />,
      <PrivateRoute path="/arabic-levels-settings" render={props => <ArabicLevelsForm {...props} />} />,
      <PrivateRoute path="/test-appointments-settings" render={props => <TestAppointmentsForm {...props} />} />,
      <PrivateRoute path="/course-periods-settings" render={props => <CoursePeriodsForm {...props} />} />,
    ],
    TRAINING_EMP: [
      <PrivateRoute exact path="/training-lookups" render={props => <TrainingLookups {...props} />} />,
      <PrivateRoute exact path="/training-list" render={props => <TrainingList {...props} />} />,

      <PrivateRoute path="/training-settings" render={props => <TrainingForm {...props} />} />,
    ],
    CONFIG_SUPERVISOR: [
      <PrivateRoute path="/postGraduate" render={props => <ListLookupsPost {...props} />} />,
      <PrivateRoute path="/post-graduate-settings" render={props => <PostGraduateForm {...props} />} />,
      <PrivateRoute path="/post-graduate-faculties" render={props => <PostGraduateFaculties {...props} />} />,
      <PrivateRoute path="/post-graduate-list" render={props => <PostManagementList {...props} />} />,

      <PrivateRoute path="/certificates" render={props => <CertificateList {...props} />} />,
      <PrivateRoute path="/certificate-form" component={CertificateForm} render={props => <CertificateForm {...props} />} />,
      <PrivateRoute path="/coordination-list" render={props => <CoordinationList {...props} />} />,
      <PrivateRoute path="/equations-list" render={props => <EquationsList {...props} />} />,
      <PrivateRoute path="/equations-settings" render={props => <EquationForm {...props} />} />,
      <PrivateRoute path="/coordination-settings" render={props => <CoordinationForm {...props} />} />,
      <PrivateRoute path="/coordination-faculties" render={props => <CoordinationFaculties {...props} />} />,
      <PrivateRoute path="/test-appointments-list" render={props => <TestAppointmentsList {...props} />} />,
      <PrivateRoute path="/test-appointments-settings" render={props => <TestAppointmentsForm {...props} />} />,
      <PrivateRoute path="/course-periods-list" render={props => <PeriodsList {...props} />} />,
      <PrivateRoute path="/course-periods-settings" render={props => <CoursePeriodsForm {...props} />} />,

      <PrivateRoute exact path="/arabic-dep" render={props => <ArabicDepLookups {...props} />} />,
      <PrivateRoute exact path="/arabic-programs" render={props => <ArabicDepPrograms {...props} />} />,
      <PrivateRoute path="/arabic-programs-settings" render={props => <ArabicDepProgramsForm {...props} />} />,
      <PrivateRoute path="/arabic-skills-list" render={props => <ArabicSkillsList {...props} />} />,
      <PrivateRoute path="/arabic-skills-settings" component={ArabicSkillsForm} render={props => <ArabicSkillsForm {...props} />} />,
      <PrivateRoute path="/arabic-levels-list" render={props => <ArabicLevelsList {...props} />} />,
      <PrivateRoute path="/arabic-levels-settings" render={props => <ArabicLevelsForm {...props} />} />,

      <PrivateRoute exact path="/training-lookups" render={props => <TrainingLookups {...props} />} />,
      <PrivateRoute exact path="/training-list" render={props => <TrainingList {...props} />} />,
      <PrivateRoute path="/training-settings" render={props => <TrainingForm {...props} />} />,

      <PrivateRoute exact path="/lookups" render={props => <ListLookups {...props} />} />,
      <PrivateRoute path="/general-lookups" render={props => <GeneralLookups {...props} />} />,
      <PrivateRoute path="/fees-list" render={props => <FeesList {...props} />} />,
      <PrivateRoute path="/effective-information-list" render={props => <EffectiveInformationList {...props} />} />,
      <PrivateRoute path="/employee-signatures" render={props => <SignaturesList {...props} />} />,
      <PrivateRoute path="/fees-settings" render={props => <FeesForm {...props} />} />,
      <PrivateRoute path="/effective-information-settings" render={props => <EffectiveInformationFrom {...props} />} />,
      <PrivateRoute path="/candidacy-limit-list" render={props => <CandidacyLimitList {...props} />} />,
      <PrivateRoute path="/ug-study-stages" render={props => <StudyStagesUg {...props} />} />,
      <PrivateRoute path="/candidacy-limit-settings" render={props => <CandidacyLimitForm {...props} />} />,

      <PrivateRoute path="/Payment-effective-information-list" render={props => <PaymentEffectiveInformationList {...props} />} />,
      <PrivateRoute path="/Payment-effective-information-settings" render={props => <PaymentEffectiveInformationFrom {...props} />} />,
      <Redirect path="/" to="/general-lookups" exact />,
    ],
  }), []);

  const ROLES_HOME_ROUTES = useMemo(() => ({
    PG_EMP: ['/postGraduate'],
    UG_EMP: ['/lookups'],
    ARABIC_EMP: ['/arabic-dep'],
    TRAINING_EMP: ['/training-lookups'],
    CONFIG_SUPERVISOR: ['/general-lookups'],
  }), []);

  if (!isAuth && location.pathname !== '/login') {
    return <Redirect to="/login" />;
  }

  if (isAuth && location.pathname === '/') {
    return (
      <Redirect to={roleIds.flatMap(roleId => ROLES_HOME_ROUTES[roleId])[0]} />
    );
  }

  return (
    <Suspense fallback={<Spinner type="fullscreen" />}>

      <Switch>
        {roleIds.flatMap(roleId => ROLES_ROUTES[roleId])}
        <Route component={NoMatchedRoutes} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
