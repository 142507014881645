import React, { useState, useMemo } from 'react';
import Menu from 'antd/lib/menu';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CaretDownOutlined from '@ant-design/icons/CaretDownOutlined';
import _get from 'lodash/get';
import ConfigProvider from 'antd/lib/config-provider';
import { actions } from '../../redux/actions';
import styles from './header.module.scss';
import Spinner from '../UIElements/Spinner/index';
import i18next from '../../i18next';

const { SubMenu } = Menu;
const Header = ({ handleLogout, history, location }) => {
  const roleIds = useSelector(state => _get(state, 'userReducer.userData.roles', []).map(role => role.id));
  const currentKey = location.pathname.split('/')[1];
  const [key, setKey] = useState(currentKey);
  const handleClick = ({ key }) => {
    setKey(key);
    history.push(`/${key}`);
  };
  const fullName = localStorage.getItem('fullName');
  const ROLES_LINKS = useMemo(() => ({
    PG_EMP: [
      <SubMenu
        title={(
          <>
            <span className={styles['submenu-title']}>
              {i18next.t('header.postgraduate')}
            </span>
            <CaretDownOutlined />
          </>
        )}
      >
        <Menu.Item key="postGraduate">
          {i18next.t('header.postgraduateLookups')}
        </Menu.Item>
        <Menu.Item key="post-graduate-list">
          {i18next.t('header.postgraduateSettings')}
        </Menu.Item>
      </SubMenu>,

    ],
    TRAINING_EMP: [
      <SubMenu
        title={(
          <>
            <span className={styles['submenu-title']}>
              {i18next.t('header.training')}
            </span>
            <CaretDownOutlined />
          </>
        )}
      >
        <Menu.Item key="training-lookups">
          {i18next.t('header.trainingLookups')}
        </Menu.Item>
        <Menu.Item key="training-list">
          {i18next.t('header.trainingSettings')}
        </Menu.Item>
      </SubMenu>,

    ],
    ARABIC_EMP: [
      <SubMenu
        title={(
          <>
            <span className={styles['submenu-title']}>
              {i18next.t('header.arabicDep')}
            </span>
            <CaretDownOutlined />
          </>
        )}
      >
        <Menu.Item key="arabic-dep">
          {i18next.t('header.arabicLookups')}
        </Menu.Item>
        <Menu.Item key="arabic-programs">
          {i18next.t('header.arabicProgramsSettings')}
        </Menu.Item>
        <Menu.Item key="arabic-skills-list">
          {i18next.t('header.arabicSkillsSettings')}
        </Menu.Item>
        <Menu.Item key="arabic-levels-list">
          {i18next.t('header.arabicLevelsSettings')}
        </Menu.Item>
        <Menu.Item key="course-periods-list">
          {i18next.t('header.coursePeriodsSettings')}
        </Menu.Item>
        <Menu.Item key="test-appointments-list">
          {i18next.t('header.testTimeSettings')}
        </Menu.Item>
      </SubMenu>,
    ],
    UG_EMP: [
      <SubMenu
        title={(
          <>
            <span className={styles['submenu-title']}>
              {i18next.t('header.university')}
            </span>
            <CaretDownOutlined />
          </>
        )}
      >
        <Menu.Item key="lookups">
          {i18next.t('header.universityLookups')}
        </Menu.Item>
        <Menu.Item key="certificates">
          {i18next.t('header.certificates')}
        </Menu.Item>
        <Menu.Item key="coordination-list">
          {i18next.t('header.coordination')}
        </Menu.Item>
      </SubMenu>,
    ],
    CONFIG_SUPERVISOR: [
      <Menu.Item key="general-lookups">{i18next.t('header.generalLists')}</Menu.Item>,

      <SubMenu title={(
        <>
          <span className={styles['submenu-title']}>
            {i18next.t('header.genralSettings')}
          </span>
          <CaretDownOutlined />
        </>
      )}
      >
        <Menu.Item key="fees-list">{i18next.t('header.feesSettings')}</Menu.Item>
        <Menu.Item key="effective-information-list">{i18next.t('header.effectiveInformationSettings')}</Menu.Item>
        <Menu.Item key="payment-effective-information-list">{i18next.t('header.PaymentEffectiveInformationSettings')}</Menu.Item>
        <Menu.Item key="employee-signatures">{i18next.t('header.employeeSignatures')}</Menu.Item>
      </SubMenu>,
      <SubMenu
        title={(
          <>
            <span className={styles['submenu-title']}>
              {i18next.t('header.university')}
            </span>
            <CaretDownOutlined />
          </>
        )}
      >
        <Menu.Item key="lookups">
          {i18next.t('header.universityLookups')}
        </Menu.Item>
        <Menu.Item key="certificates">
          {i18next.t('header.certificates')}
        </Menu.Item>
        <Menu.Item key="coordination-list">
          {i18next.t('header.coordination')}
        </Menu.Item>
        <Menu.Item key="equations-list">
          {i18next.t('header.equationsSettings')}
        </Menu.Item>
        <Menu.Item key="candidacy-limit-list">{i18next.t('header.candidacyLimit')}</Menu.Item>
        <Menu.Item key="ug-study-stages">{i18next.t('header.studyStages')}</Menu.Item>
      </SubMenu>,
      <SubMenu
        title={(
          <>
            <span className={styles['submenu-title']}>
              {i18next.t('header.postgraduate')}
            </span>
            <CaretDownOutlined />
          </>
        )}
      >
        <Menu.Item key="postGraduate">
          {i18next.t('header.postgraduateLookups')}
        </Menu.Item>
        <Menu.Item key="post-graduate-list">
          {i18next.t('header.postgraduateSettings')}
        </Menu.Item>
      </SubMenu>,
      <SubMenu
        title={(
          <>
            <span className={styles['submenu-title']}>
              {i18next.t('header.training')}
            </span>
            <CaretDownOutlined />
          </>
        )}
      >
        <Menu.Item key="training-lookups">
          {i18next.t('header.trainingLookups')}
        </Menu.Item>
        <Menu.Item key="training-list">
          {i18next.t('header.trainingSettings')}
        </Menu.Item>
      </SubMenu>,
      <SubMenu
        title={(
          <>
            <span className={styles['submenu-title']}>
              {i18next.t('header.arabicDep')}
            </span>
            <CaretDownOutlined />
          </>
        )}
      >
        <Menu.Item key="arabic-dep">
          {i18next.t('header.arabicLookups')}
        </Menu.Item>
        <Menu.Item key="arabic-programs">
          {i18next.t('header.arabicProgramsSettings')}
        </Menu.Item>
        <Menu.Item key="arabic-skills-list">
          {i18next.t('header.arabicSkillsSettings')}
        </Menu.Item>
        <Menu.Item key="arabic-levels-list">
          {i18next.t('header.arabicLevelsSettings')}
        </Menu.Item>
        <Menu.Item key="course-periods-list">
          {i18next.t('header.coursePeriodsSettings')}
        </Menu.Item>
        <Menu.Item key="test-appointments-list">
          {i18next.t('header.testTimeSettings')}
        </Menu.Item>
      </SubMenu>,
    ],
  }), []);

  return (
    <div className={styles.header}>
      <div className={styles['image-container']}>
        <img
          src="../images/Small-Header-Study-in-Egypt-logo-ar.png"
          alt="logo"
        />
      </div>
      <div className={styles['menu-container']}>
        <ConfigProvider>

          <Menu
            className={styles.menu}
            defaultSelectedKeys={[key]}
            onClick={handleClick}
            mode="horizontal"
          >
            {
              roleIds.includes('CONFIG_SUPERVISOR') ? ROLES_LINKS.CONFIG_SUPERVISOR : roleIds.flatMap(roleId => ROLES_LINKS[roleId])
            }
            {/* <Menu.Item key='sandbox'>الحسابات</Menu.Item> */}
          </Menu>
        </ConfigProvider>
      </div>

      <div className={styles['btn-container']}>
        <span className={styles['user-name']}>{fullName}</span>
        <button type="button" className={styles['logout-btn']} onClick={() => handleLogout()}>
          {i18next.t('header.logout')}
        </button>
      </div>
    </div>
  );
};
export default () => {
  const { userModel, authMeta } = useSelector(state => ({
    authMeta: state.userReducer.authMeta,
    userModel: state.userReducer.model,
  }));
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const handleLogout = () => {
    setloading(true);
    const logoutUrl = userModel.IAM ? authMeta.logoutUrl : undefined;
    dispatch(actions.logout(logoutUrl)).finally((res) => {
      window.location.href = logoutUrl;
    });
  };
  const location = useLocation();

  if (loading) return <Spinner type="fullscreen" />;
  return (
    <Header handleLogout={handleLogout} location={location} history={history} />
  );
};
