import React, { useState, useEffect } from 'react';

import Spin from 'antd/lib/spin';

import CustomForm from '../../Components/CustomForm/CustomForm';

import styles from './LookupAction.module.scss';

const LookupAction = ({
  lookupType, handleSubmit, item, loading, count, editModal,
}) => {
  const studyStagesListsNames = [
    'studyStages',
    'ugEditNominationStudyStages',
    'ugRenominationStudyStages',
    'ugPostponingEnrollmentStudyStages',
    'ugTransferStudyStages',
    'ugInternalTransferStudyStages',
    'ugTransferAbroadStudyStages',
    'scholarShipStudyStages',
  ];

  const [schema, setSchema] = useState(null);
  useEffect(() => {
    const getSchema = async () => {
      if (studyStagesListsNames.includes(lookupType)) {
        const module = await import('../../FormSchemas/ugStudyStages');
        setSchema(await module.schema({ item, tabKey: lookupType }));
      } else {
        const module = await import(`../../FormSchemas/${lookupType}`);
        setSchema(await module.schema({ item }));
      }
    };
    getSchema();
  }, [item, lookupType]);

  return (!loading && schema) ? (
    <CustomForm handleSubmit={handleSubmit} item={item} schema={schema} lookupType={lookupType} count={count} editModal={editModal} />
  )
    : (<div className={styles.spinner}><Spin size="large" tip={loading ? 'برجاء الانتظار لحين الانتهاء من الاضافة ...' : 'تحميل ...'} /></div>);
};

export default LookupAction;
